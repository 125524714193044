/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import { useTable, usePagination } from "react-table"

const ApplicantsModal = ({ isOpen, onClose, jobId }) => {
  const [applicants, setApplicants] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    if (isOpen && jobId) {
      console.log("Fetching applicants for jobId:", jobId)
      fetchApplicants(currentPage)
    }
  }, [isOpen, currentPage, jobId])

  const fetchApplicants = async (page) => {
    setLoading(true)
    try {
      const token = localStorage.getItem("token")
      const response = await fetch(`https://job.aptechmedia.com/api/singleJobApplicants/${jobId}?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const data = await response.json()

      if (Array.isArray(data.data.data)) {
        setApplicants(data.data.data)
        setCurrentPage(data.data.current_page)
        setTotalPages(data.data.last_page)
      } else {
        setApplicants([])
      }
    } catch (error) {
      console.error("Failed to fetch applicants", error)
    } finally {
      setLoading(false)
    }
  }

  const columns = React.useMemo(
    () => [
      { Header: "Name", accessor: "name" },
      { Header: "Email", accessor: "email" },
      { Header: "Applied Date", accessor: "applied_date" },
      { Header: "Contact No", accessor: "contactNo" }
    ],
    []
  )

  const tableInstance = useTable({ columns, data: applicants }, usePagination)

  const renderPagination = () => (
    <div className="flex justify-between items-center mt-4">
      <button
        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
        disabled={currentPage === 1}
        className="px-4 py-2 bg-gray-200 text-gray-700 rounded disabled:opacity-50"
      >
        Previous
      </button>
      <span>
        Page {currentPage} of {totalPages}
      </span>
      <button
        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
        disabled={currentPage === totalPages}
        className="px-4 py-2 bg-gray-200 text-gray-700 rounded disabled:opacity-50"
      >
        Next
      </button>
    </div>
  )

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Applicants Modal" className="modal">
      <h2 className="text-xl font-bold mb-4">Applicants for Job ID: {jobId}</h2>
      {loading ? (
        <div>Loading...</div>
      ) : applicants.length === 0 ? (
        <div>No Applicants Found</div>
      ) : (
        <div className="overflow-x-auto">
          <table {...tableInstance.getTableProps()} className="min-w-full bg-white border border-gray-200">
            <thead>
              {tableInstance.headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-100">
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()} className="px-4 py-2 border">
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...tableInstance.getTableBodyProps()}>
              {tableInstance.rows.map(row => {
                tableInstance.prepareRow(row)
                return (
                  <tr {...row.getRowProps()} className="border-t">
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()} className="px-4 py-2 border">
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
          {renderPagination()}
        </div>
      )}
      <button onClick={onClose} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded">
        Close
      </button>
    </Modal>
  )
}

export default ApplicantsModal