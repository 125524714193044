const ContentSection = ({ videoSrc, imgSrc, title, subTitle, description, center }) => {
  const lgOrderClass = center ? "lg:order-1" : "lg:order-2"

  return (
    <div className="max-w-screen-xl mx-auto px-20 mt-10">
      <div className="flex flex-col lg:flex-row justify-between">
        {videoSrc && (
          <div className={`w-full lg:w-1/3 overflow-hidden shadow-lg ${lgOrderClass}`}>
            <video
              className="w-full rounded-2xl"
              src={videoSrc}
              autoPlay
              controls={false}
              muted
              loop
            ></video>
          </div>
        )}
        {imgSrc && (
          <div className={`w-full lg:w-1/3 overflow-hidden shadow-lg ${lgOrderClass}`}>
            <img
              className="w-full rounded-2xl"
              src={imgSrc}
              alt={title}
            />
          </div>
        )}
        <div className="w-full lg:w-1/2 lg:order-1 flex justify-center lg:justify-start">
          <div className="flex flex-col justify-center items-start text-left sm:mt-8 md:mt-7 xs:mt-10">
            <p className="text-sm font-semibold text-blue-700">{title}</p>
            <h2 className="text-1xl lg:text-5xl font-bold text-black mb-4 sm:text-3xl md:text-4xl">
              {subTitle}
            </h2>
            <p className="text-black lg:text-xl sm:text-lg mt-5">{description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContentSection
