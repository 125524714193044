import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { apiCall } from "../lib/api"

const Job = ({ data }) => {
  if (!data) {
    return null
  }

  const {
    department,
    contract,
    featured,
    languages,
    level,
    location,
    position,
    postedAt,
    role,
    tools,
    lastDateToApply
  } = data

  const keywords = [role, level, ...languages, ...tools]

  return (
    <div
      className={`w-full sm:w-3/4 bg-white flex flex-col sm:flex-row items-center p-6 rounded shadow-lg my-4 transition-transform transform hover:scale-105 ${
        featured ? "border-l-4 border-blue-600" : ""
      }`}
    >
      <div className="flex-grow w-full">
        <div className="flex items-center justify-between mb-2">
          <div>
            <span className="font-bold text-blue-600 mr-2">{department}</span>
            {data.featured && (
              <span className="bg-gray-700 text-white rounded-full px-2 py-1 text-xs">
                featured
              </span>
            )}
          </div>
          <div className="text-gray-600">
            <strong>Last Date to Apply:</strong> {lastDateToApply}
          </div>
        </div>
        <div className="font-bold text-lg mb-2 cursor-pointer hover:text-blue-600">
          {position}
        </div>
        <div className="text-gray-600 mb-4">
          <span>{postedAt}</span>
          <span className="mx-2">•</span>
          <span>{contract}</span>
          <span className="mx-2">•</span>
          <span>{location}</span>
        </div>
        <div className="flex flex-wrap justify-start mb-2">
          {keywords.map((key, id) => (
            <span
              key={id}
              className="bg-gray-200 text-blue-600 font-bold p-2 rounded cursor-pointer mr-2 mb-2"
            >
              {key}
            </span>
          ))}
        </div>
        <div className="flex justify-end">
          <Link
            to={`/careers/apply/${data.id}`}
            state={{ job: data }}
            className="bg-blue-600 text-white rounded-full px-4 py-2 text-xs hover:bg-blue-700"
          >
            Apply Now
          </Link>
        </div>
      </div>
    </div>
  )
}

const Jobs = ({ data }) => (
  <div className="flex flex-col items-center mt-8">
    {data.map((d) => (
      <Job key={d.id} data={d} />
    ))}
  </div>
)

const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div className="flex justify-center mt-4">
    {[...Array(totalPages)].map((_, index) => (
      <button
        key={index}
        onClick={() => onPageChange(index + 1)}
        className={`mx-1 px-3 py-1 rounded ${index + 1 === currentPage ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-700"} transition-colors duration-300`}
      >
        {index + 1}
      </button>
    ))}
  </div>
)

const JobApply = () => {
  const [jobs, setJobs] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    const fetchJobs = async (page) => {
      try {
        const response = await apiCall("get", `/api/getJobList?page=${page}`)
        if (response.status === 1) {
          setJobs(response.data.data)
          setCurrentPage(response.data.current_page)
          setTotalPages(response.data.last_page)
        }
      } catch (error) {
        console.error("Error fetching jobs:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchJobs(currentPage)
  }, [currentPage])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  return (
    <div>
      <div
        className="relative w-full h-96 bg-cover bg-center"
        style={{
          backgroundImage:
            "url(https://imageio.forbes.com/specials-images/imageserve/458956405/0x0.jpg?format=jpg&height=900&width=1600&fit=bounds)"
        }}
      >
        <div className="absolute inset-0 bg-black opacity-70 h-full"></div>
        <div className="relative flex flex-col items-center justify-center h-full text-center text-white">
          <h1 className="text-3xl font-bold mb-2">Join Our Team</h1>
          <p className="text-lg">
            Explore exciting job opportunities and apply to be a part of our
            dynamic team.
          </p>
        </div>
      </div>
      <div className="py-8">
        {loading ? (
          <div className="flex justify-center items-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
          </div>
        ) : jobs.length === 0 ? (
          <div className="text-center text-gray-500">No jobs posted yet</div>
        ) : (
          <>
            <Jobs data={jobs} />
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          </>
        )}
      </div>
    </div>
  )
}

export default JobApply