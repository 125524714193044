import { FaUserTie, FaBriefcase, FaRegClock, FaUsers, FaLaptopCode, FaHeart } from "react-icons/fa"
import teamVideo from "../assets/team/team_video.mp4"

const Careers = () => {
  return (
    <>
      <div className="relative">
        <video autoPlay loop muted className="absolute inset-0 object-cover w-full h-full z-0">
          <source src={teamVideo} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        <div className="absolute inset-0 bg-black opacity-65"></div>
        <div className="container mx-auto p-4 flex flex-col justify-center items-center h-screen relative z-10">
          <h1 className="text-5xl md:text-4xl lg:text-5xl xl:text-7xl font-bold text-white mb-8 sm:text-2xl xs:text-2xl text-center">Engage a Worldwide Audience</h1>
          <div className="flex">
            <div className="flex-1 text-center mx-8 md:mx-12 my-4 sm:my-0">
              <div className="text-white font-semibold text-4xl md:text-xl lg:text-4xl xs:text-lg">2</div>
              <div className="text-white">nationalities</div>
            </div>
            <div className="border border-white h-14 hidden sm:block"></div>
            <div className="flex-1 text-center mx-8 md:mx-12 my-4 sm:my-0">
              <div className="text-white font-semibold text-4xl md:text-xl lg:text-4xl xs:text-lg">35</div>
              <div className="text-white">employees</div>
            </div>
            <div className="border border-white h-14 hidden sm:block"></div>
            <div className="flex-1 text-center mx-8 md:mx-12 my-4 sm:my-0">
              <div className="text-white font-semibold text-4xl md:text-xl lg:text-4xl xs:text-lg">1</div>
              <div className="text-white">offices worldwide</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto p-4 pt-6 md:pt-12 flex flex-col items-center">
        <h1 className="text-4xl md:text-3xl lg:text-4xl xl:text-6xl font-bold text-black mb-8 sm:text-xl xs:text-xl text-center">Why Join Us</h1>
        <p className="text-gray-700 lg:text-2xl sm:text-base p-2 rounded mt-4 text-center">
          At AptechMedia, we believe in fostering a collaborative and innovative work environment. Here are some reasons why you should join our team:
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
          <div className="flex flex-col items-center bg-blue-100 p-6 rounded-lg shadow-lg hover:bg-blue-200 transition duration-200">
            <FaUserTie className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Professional Growth</h3>
            <p className="text-center text-gray-700">We provide opportunities for continuous learning and career advancement.</p>
          </div>
          <div className="flex flex-col items-center bg-blue-100 p-6 rounded-lg shadow-lg hover:bg-blue-200 transition duration-200">
            <FaBriefcase className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Challenging Projects</h3>
            <p className="text-center text-gray-700">Work on exciting and innovative projects that push the boundaries of technology.</p>
          </div>
          <div className="flex flex-col items-center bg-blue-100 p-6 rounded-lg shadow-lg hover:bg-blue-200 transition duration-200">
            <FaRegClock className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Work-Life Balance</h3>
            <p className="text-center text-gray-700">Enjoy a healthy work-life balance with flexible working hours and remote work options.</p>
          </div>
          <div className="flex flex-col items-center bg-blue-100 p-6 rounded-lg shadow-lg hover:bg-blue-200 transition duration-200">
            <FaUsers className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Team Collaboration</h3>
            <p className="text-center text-gray-700">Work with a diverse and talented team to achieve common goals.</p>
          </div>
          <div className="flex flex-col items-center bg-blue-100 p-6 rounded-lg shadow-lg hover:bg-blue-200 transition duration-200">
            <FaLaptopCode className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Innovative Technologies</h3>
            <p className="text-center text-gray-700">Utilize cutting-edge technologies to create impactful solutions.</p>
          </div>
          <div className="flex flex-col items-center bg-blue-100 p-6 rounded-lg shadow-lg hover:bg-blue-200 transition duration-200">
            <FaHeart className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Positive Culture</h3>
            <p className="text-center text-gray-700">Be part of a positive and inclusive work culture that values everyone.</p>
          </div>
        </div>
      </div>

      <div className="w-full bg-blue-50 mt-10">
        <div className="container mx-auto p-4 pt-6 md:pt-12 flex flex-col items-center">
          <h1 className="text-4xl md:text-3xl lg:text-4xl xl:text-6xl font-bold text-black mb-8 sm:text-xl xs:text-xl text-center">Our Values</h1>
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-8 mt-8">
            {[
              { icon: "⚡", text: "Push for speed" },
              { icon: "🔥", text: "Take risks" },
              { icon: "💬", text: "Share and accept feedback" },
              { icon: "❌", text: "Fight BS & politics" },
              { icon: "🎯", text: "Set the highest standards" },
              { icon: "📅", text: "Act like an owner" },
              { icon: "🔧", text: "Disrupt the status quo" },
              { icon: "🏅", text: "Put the company’s interest first" },
              { icon: "🚀", text: "Think big" },
              { icon: "⭐", text: "Think long-term" }
            ].map((value, index) => (
              <div key={index} className="flex flex-col items-center">
                <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                  <span className="text-2xl">{value.icon}</span>
                </div>
                <p className="text-center text-gray-700 font-semibold">{value.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container mx-auto p-4 pt-6 md:pt-12 flex flex-col items-center">
        <h1 className="text-4xl md:text-3xl lg:text-4xl xl:text-6xl font-bold text-black mb-8 sm:text-xl xs:text-xl text-center">Join the Team</h1>
        <p className="text-gray-700 lg:text-2xl sm:text-base p-2 rounded mt-4 text-center">
          We’re looking for talented individuals from across the globe to come and entertain the world with us.
        </p>
        <a href="/careers/apply" className="bg-blue-600 hover:bg-blue-700 text-white text-lg px-4 py-2 border text-center rounded-full mt-4">
          See our open jobs
        </a>
      </div>
    </>
  )
}

export default Careers