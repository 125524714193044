import { Link } from "react-router-dom"
import logo from "../../assets/logo.png"

const Footer = () => {
  return (
    <footer className="bg-white dark:bg-gray-900 mt-12">
      <div className="mx-auto w-full max-w-screen-xl">
        <div className="px-4 py-2 lg:py-4 flex flex-col lg:flex-row lg:items-center lg:justify-between">
          <Link to="/" className="flex items-center space-x-3 lg:mb-0">
            <img src={logo} className="h-8" alt="Aptechmedia Logo" />
            <span className="text-2xl font-semibold whitespace-nowrap text-gray-900 dark:text-white">Aptechmedia</span>
          </Link>
        </div>

        <div className="flex flex-col lg:flex-row-reverse">
          <div className="grid grid-cols-2 gap-4 sm:gap-8 md:gap-16 lg:gap-48 px-4 lg:px-0 py-6 lg:py-8 md:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Company</h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <Link to="/games" className="hover:underline">Games</Link>
                </li>
                <li className="mb-4">
                  <Link to="/publishing" className="hover:underline">Publishing</Link>
                </li>
                <li className="mb-4">
                  <Link to="/apps" className="hover:underline">Apps</Link>
                </li>
                <li className="mb-4">
                  <Link to="/apps" className="hover:underline">Team</Link>
                </li>
                <li className="mb-4">
                  <Link to="/careers" className="hover:underline">Careers</Link>
                </li>
                <li className="mb-4">
                  <Link to="/careers" className="hover:underline">Contact</Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Help center</h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="https://www.facebook.com/people/AptechMedia/61558329702611/" rel="noreferrer" className="hover:underline" target="_blank">Facebook</a>
                </li>
                <li className="mb-4">
                  <a href="https://www.linkedin.com/company/aptechmedia/?originalSubdomain=pk" rel="noreferrer" target="_blank" className="hover:underline">LinkedIn</a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="/privacy-policy" className="hover:underline">Privacy Policy</a>
                </li>
                <li className="mb-4">
                  <a href="/terms-conditions" className="hover:underline">Terms &amp; Conditions</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
