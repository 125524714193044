import { useState, useEffect } from "react"
import Modal from "react-modal"
import Select from "react-select"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { apiCall } from "../../lib/api"

const EditJobModal = ({ isOpen, onClose, job }) => {
  const [formData, setFormData] = useState({
    department: "",
    featured: false,
    position: "",
    role: "",
    level: "",
    location: "",
    contract: "",
    postedAt: "",
    lastDateToApply: "",
    languages: [],
    tools: [],
    description: ""
  })

  useEffect(() => {
    if (job) {
      setFormData({
        ...job,
        languages: job.languages ? job.languages.map(lang => ({ value: lang, label: lang })) : [],
        tools: job.tools ? job.tools.map(tool => ({ value: tool, label: tool })) : [],
        description: job.description || ""
      })
    }
  }, [job])

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value
    }))
  }

  const handleSelectChange = (selectedOptions, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: selectedOptions
    }))
  }

  const handleDescriptionChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      description: value
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const updatedFormData = {
      ...formData,
      languages: formData.languages.map(option => option.value),
      tools: formData.tools.map(option => option.value)
    }
    try {
      const response = await apiCall("post", `/api/updateJob/${job.id}`, updatedFormData, {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      })
      if (response.status === 1) {
        onClose()
      } else {
        console.error("Failed to update job:", response.message)
      }
    } catch (error) {
      console.error("Error updating job:", error)
    }
  }

  const languageOptions = [
    { value: "HTML", label: "HTML" },
    { value: "CSS", label: "CSS" },
    { value: "JavaScript", label: "JavaScript" },
    { value: "React", label: "React" },
    { value: "Node.js", label: "Node.js" },
    { value: "Python", label: "Python" },
    { value: "Java", label: "Java" },
    { value: "C#", label: "C#" },
    { value: "PHP", label: "PHP" },
    { value: "Ruby", label: "Ruby" },
    { value: "Blockchain", label: "Blockchain" },
    { value: "Flutter", label: "Flutter" },
    { value: "Unity 3D", label: "Unity 3D" },
    { value: "React Native", label: "React Native" },
    { value: "Angular", label: "Angular" }
  ]

  const toolOptions = [
    { value: "Git", label: "Git" },
    { value: "Docker", label: "Docker" },
    { value: "Kubernetes", label: "Kubernetes" },
    { value: "Jenkins", label: "Jenkins" },
    { value: "Webpack", label: "Webpack" },
    { value: "Babel", label: "Babel" },
    { value: "AWS", label: "AWS" },
    { value: "Azure", label: "Azure" },
    { value: "GCP", label: "GCP" },
    { value: "Figma", label: "Figma" },
    { value: "Sketch", label: "Sketch" },
    { value: "Adobe XD", label: "Adobe XD" },
    { value: "Photoshop", label: "Photoshop" },
    { value: "Illustrator", label: "Illustrator" },
    { value: "InDesign", label: "InDesign" }
  ]

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Edit Job"
      className="modal"
      overlayClassName="overlay"
    >
      <h2 className="text-2xl font-bold mb-4">Edit Job</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="department">
            Department
          </label>
          <input
            type="text"
            id="department"
            name="department"
            value={formData.department}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="featured">
            Featured
          </label>
          <input
            type="checkbox"
            id="featured"
            name="featured"
            checked={formData.featured}
            onChange={handleChange}
            className="mr-2 leading-tight"
          />
          <span className="text-sm">{formData.featured ? "Yes" : "No"}</span>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="position">
            Position
          </label>
          <input
            type="text"
            id="position"
            name="position"
            value={formData.position}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="role">
            Role
          </label>
          <input
            type="text"
            id="role"
            name="role"
            value={formData.role}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="level">
            Level
          </label>
          <input
            type="text"
            id="level"
            name="level"
            value={formData.level}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="location">
            Location
          </label>
          <input
            type="text"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contract">
            Contract
          </label>
          <input
            type="text"
            id="contract"
            name="contract"
            value={formData.contract}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="postedAt">
            Posted At
          </label>
          <input
            type="date"
            id="postedAt"
            name="postedAt"
            value={formData.postedAt}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastDateToApply">
            Last Date to Apply
          </label>
          <input
            type="date"
            id="lastDateToApply"
            name="lastDateToApply"
            value={formData.lastDateToApply}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="languages">
            Languages
          </label>
          <Select
            id="languages"
            name="languages"
            isMulti
            options={languageOptions}
            value={formData.languages}
            onChange={(selectedOptions) => handleSelectChange(selectedOptions, "languages")}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tools">
            Tools
          </label>
          <Select
            id="tools"
            name="tools"
            isMulti
            options={toolOptions}
            value={formData.tools}
            onChange={(selectedOptions) => handleSelectChange(selectedOptions, "tools")}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
            Description
          </label>
          <ReactQuill
            id="description"
            value={formData.description}
            onChange={handleDescriptionChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-64"
            required
          />
        </div>
        <div className="flex items-center justify-between mt-4">
          <button
            type="submit"
            className="bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-blue-700"
          >
            Save Changes
          </button>
          <button
            type="button"
            onClick={onClose}
            className="bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-red-700"
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default EditJobModal