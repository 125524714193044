const Card = ({ icon, title, description }) => {
  return (
    <div className="w-80 bg-blue-100 p-10 rounded-lg flex flex-col items-center justify-center">
      <div className="w-16 h-16 flex items-center justify-center rounded-full bg-blue-700 mb-4">
        {icon}
      </div>
      <h3 className="text-xl font-bold text-black mb-2">{title}</h3>
      <p className="text-black">{description}</p>
    </div>
  )
}

export default Card
