import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa"

const Contact = () => {
  return (
    <div className="w-full bg-blue-50 py-12">
      <div className="container mx-auto px-4 md:px-8 lg:px-12 flex flex-col items-center">
        <h1 className="text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold text-black mb-8 text-center">Contact Us</h1>
        <p className="text-lg text-gray-700 mb-12 text-center max-w-2xl">
          Have a question? A brilliant game idea? Or just want to say hello to the team? Drop us a line! At AptechMedia, we&apos;re always eager to hear from fellow game enthusiasts and potential partners. Let&apos;s start a conversation that could lead to the next big hit in mobile gaming!
        </p>

        <div className="w-full flex flex-wrap justify-around items-center mb-16 space-y-8 md:space-y-0">
          <div className="flex items-center space-x-4 max-w-xs">
            <div className="bg-white p-4 rounded-full">
              <FaMapMarkerAlt className="w-8 h-8 text-blue-500" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Our Office</h3>
              <p className="text-gray-700">KPIT Park, PTCL Training Center, Canal Town Peshawar, Khyber Pakhtunkhwa 25000</p>
            </div>
          </div>
          <div className="flex items-center space-x-4 max-w-xs">
            <div className="bg-white p-4 rounded-full">
              <FaPhoneAlt className="w-8 h-8 text-blue-500" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Phone</h3>
              <p className="text-gray-700">+92-3339371781</p>
            </div>
          </div>
          <div className="flex items-center space-x-4 max-w-xs">
            <div className="bg-white p-4 rounded-full">
              <FaEnvelope className="w-8 h-8 text-blue-500" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Email</h3>
              <p className="text-gray-700">Info@aptechmedia.com</p>
            </div>
          </div>
        </div>

        <div className="w-full bg-white p-8 rounded-lg shadow-lg mb-16">
          <h3 className="text-2xl font-semibold mb-4 text-center">Get in Touch</h3>
          <form>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Name</label>
              <input type="text" className="w-full p-3 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500" />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Email</label>
              <input type="email" className="w-full p-3 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500" />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Message</label>
              <textarea className="w-full p-3 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500" rows="5"></textarea>
            </div>
            <button type="submit" className="w-full p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-200">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Contact