import ContentSection from "../components/common/ContentSection"
import Testimonals from "../components/common/Testimonal"

const Publishing = () => {
  // const Google = "https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"
  // const Google2 = "https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"
  // const Google3 = "https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"
  // const Google4 = "https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"
  // const Google5 = "https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"
  // const Google6 = "https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"

  // const clients = [
  //   {
  //     name: "google",
  //     logo: Google
  //   },
  //   {
  //     name: "asus",
  //     logo: Google2
  //   },
  //   {
  //     name: "byu",
  //     logo: Google3
  //   },
  //   {
  //     name: "kominfo",
  //     logo: Google4
  //   },
  //   {
  //     name: "Telkom",
  //     logo: Google5
  //   },
  //   {
  //     name: "Bank Indonesia",
  //     logo: Google6
  //   }
  // ]

  return (
    <>
      <div className="container mx-auto p-4 pt-6 md:pt-12">
        <h1 className="text-5xl md:text-4xl lg:text-5xl xl:text-7xl font-bold text-black mb-8 sm:text-2xl xs:text-2xl text-center">Craft the next Blockbuster <br/>Game</h1>
        <div className="flex flex-col sm:flex-row xs:flex-row md:flex-row justify-center items-center flex-wrap mt-8 mx-auto px-20 max-w-screen-xl space-y-4 sm:space-y-0 sm:space-x-4">
          <div className="flex-1 text-center mx-8 md:mx-12">
            <div className="text-blue-700 font-semibold text-4xl md:text-xl lg:text-4xl xs:text-lg">50 million</div>
            <div>downloads</div>
          </div>
          <div className="border border-black h-14 hidden sm:block"></div>
          <div className="flex-1 text-center mx-8 md:mx-12 mb-4">
            <div className="text-blue-700 font-semibold text-4xl md:text-xl lg:text-4xl xs:text-lg">#1 publisher</div>
            <div>yearly since 2018</div>
          </div>
          <div className="border border-black h-14 hidden sm:block"></div>
          <div className="flex-1 text-center mx-8 md:mx-12 mb-4">
            <div className="text-blue-700 font-semibold text-4xl md:text-xl lg:text-4xl xs:text-lg">100k</div>
            <div>monthly active users</div>
          </div>
        </div>
      </div>
      <Testimonals/>

      {/* <div className="relative">
        <img
          src="https://www.datocms-assets.com/62418/1654775899-hero-publishing.png?h=800&w=1920"
          alt="Hero Publishing"
          className="w-full h-auto"
        />
        <div className="absolute inset-0 flex flex-col justify-center items-center z-10">
          <h1 className="text-4xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-black sm:text-xl text-center mb-8">
            Craft the next Blockbuster <br/>Game
          </h1>
          <div className="flex flex-col sm:flex-row items-center justify-center w-full">
            <div className="flex-1 text-center mx-4 md:mx-8 my-2 sm:my-0">
              <div className="text-black font-semibold text-3xl md:text-lg lg:text-3xl xs:text-base whitespace-nowrap">
          50 million
              </div>
              <div className="text-black">downloads</div>
            </div>
            <div className="border border-black h-14 hidden sm:block mx-2"></div>
            <div className="flex-1 text-center mx-4 md:mx-8 my-2 sm:my-0">
              <div className="text-black font-semibold text-3xl md:text-lg lg:text-3xl xs:text-base whitespace-nowrap">
          #1 publisher
              </div>
              <div className="text-black">yearly since 2018</div>
            </div>
            <div className="border border-black h-14 hidden sm:block mx-2"></div>
            <div className="flex-1 text-center mx-4 md:mx-8 my-2 sm:my-0">
              <div className="text-black font-semibold text-3xl md:text-lg lg:text-3xl xs:text-base whitespace-nowrap">
          100k
              </div>
              <div className="text-black">monthly active users</div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div>
        <h1 className="text-xl font-bold text-black text-center my-10">Partner Studios Who Value Us</h1>
      </div>
      <div className='overflow-hidden mx-10'>
        <div className='flex animate-left gap-8 w-max'>
          <div className=" flex w-max gap-8">
            {clients.map((client, index) => (
              <a key={index} href="#" className="flex justify-center items-center p-6 w-[10rem] ">
                <img src={client.logo} alt={client.name} width={100} height={100} className="w-full h-full object-contain" />
              </a>
            ))}
          </div>
          <div className=" flex w-max gap-8">
            {clients.map((client, index) => (
              <a key={index} href="#" className="flex justify-center items-center p-6 w-[10rem] ">
                <img src={client.logo} alt={client.name} width={100} height={100} className="w-full h-full object-contain" />
              </a>
            ))}
          </div>
        </div>
      </div> */}
      <ContentSection
        imgSrc="https://www.datocms-assets.com/62418/1683810155-publishing-team.png?h=460&w=400"
        title=""
        subTitle="A Driven Team to Bring Your Next Game to Life"
        description="Kindle the spark of creativity with AptechMedia, where passion and expertise collide to create gaming marvels. Our team is your pool in the quest for the next hit game, turning bold ideas into breathtaking realities."
      />
      <ContentSection
        imgSrc="https://www.datocms-assets.com/62418/1654775388-illu-publishing-dashboard.png?h=460&w=400"
        title="STEP 1"
        subTitle="Explore Your Next Big Idea"
        description="Unleash your game's potential with AptechMedia. Here, ideas are not just thoughts—they're the seeds of the next gaming revolution. Find your idea with us, and watch it grow into an unforgettable adventure."
        center
      />
      <ContentSection
        imgSrc="https://www.datocms-assets.com/62418/1669902582-illu-publishing-tech.png?h=460&w=400"
        title="STEP 2"
        subTitle="Introduce Your Game to the World"
        description="Ready to take the gaming world by storm? Partner with AptechMedia and launch your game into the stratosphere. Where vision meets expertise, your game is destined for greatness."
      />
      <ContentSection
        imgSrc="https://www.datocms-assets.com/62418/1654775318-illu-publishing-marketing.png?h=460&w=400"
        title="STEP 3"
        subTitle="Scale and Monetize Your Success"
        description="Elevate your game from popular to profitable with AptechMedia. We specialize in transforming gaming sensations into revenue-generating powerhouses. Grow with us, and let's monetize your hit together."
        center
      />
      <div className="bg-blue-100 rounded-lg mx-4 md:mx-20 my-11 max-w-screen-xl h-auto md:h-80 md:p-8 sm:h-auto p-4 sm:p-8 mb-20">
        <div className="relative flex flex-col items-center md:flex-row sm:flex-col lg:flex-row justify-center lg:justify-between">
          <div className="text-3xl sm:text-5xl md:text-5xl font-bold text-black my-4 sm:my-4 md:my-10 z-10 text-center sm:text-center md:text-left lg:text-left lg:ml-4 xl:ml-4 2xl:ml-4 lg:mt-4 xl:mt-4 2xl:mt-4">
      Ready to start<br/> working with<br/> Aptechmedia?
          </div>
          <img
            src="https://www.datocms-assets.com/62418/1651581529-illu-rocket-bannercta-light.png"
            alt="Rocket"
            className="w-24 h-24 sm:w-96 sm:h-96 md:w-96 md:h-96 lg:w-96 lg:h-96 mt-4 sm:mt-0 lg:mt-0 z-20 rocket-img"
          />
        </div>
      </div>
    </>
  )
}

export default Publishing
