import { useState } from "react"
import { FaChevronDown, FaChevronUp } from "react-icons/fa"

const Terms = () => {
  const [openSection, setOpenSection] = useState(null)

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section)
  }

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">Terms and Conditions</h1>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("introduction")}
        >
          {openSection === "introduction" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          Introduction
        </h2>
        {openSection === "introduction" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
              Welcome to APtechMedia! These Terms and Conditions govern your use of our website and services. By accessing our website or using our services, you agree to comply with and be bound by these terms. Please read them carefully.
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("definitions")}
        >
          {openSection === "definitions" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          Definitions
        </h2>
        {openSection === "definitions" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
              “Website” refers to APtechMedia’s website.<br />
              “Services” refers to the digital solutions provided by APtechMedia.<br />
              “User” refers to anyone who accesses or uses our website or services.
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("use-of-website")}
        >
          {openSection === "use-of-website" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          Use of Website
        </h2>
        {openSection === "use-of-website" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
              <strong>Eligibility:</strong> You must be at least 18 years old to use our website and services.<br />
              <strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account information and for all activities under your account.<br />
              <strong>Prohibited Activities:</strong> You agree not to use our website or services for any illegal or unauthorized purpose.
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("services")}
        >
          {openSection === "services" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          Services
        </h2>
        {openSection === "services" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
              <strong>Description:</strong> APtechMedia offers a range of digital solutions including app development, web design, and digital marketing.<br />
              <strong>Service Agreement:</strong> A separate agreement will be provided for each service detailing the scope, deliverables, and payment terms.<br />
              <strong>Modifications:</strong> We reserve the right to modify or discontinue any service at any time without prior notice.
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("intellectual-property")}
        >
          {openSection === "intellectual-property" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          Intellectual Property
        </h2>
        {openSection === "intellectual-property" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
              <strong>Ownership:</strong> All content on the website, including text, graphics, logos, and software, is the property of APtechMedia or its licensors and is protected by copyright and trademark laws.<br />
              <strong>License:</strong> Users are granted a limited, non-exclusive, non-transferable license to access and use the website and services for personal, non-commercial use.
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("user-content")}
        >
          {openSection === "user-content" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          User Content
        </h2>
        {openSection === "user-content" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
              <strong>Responsibility:</strong> Users are responsible for any content they upload or post on our website.<br />
              <strong>Rights:</strong> By posting content, you grant APtechMedia a worldwide, royalty-free, perpetual, and non-exclusive license to use, reproduce, modify, and display such content.
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("payment-terms")}
        >
          {openSection === "payment-terms" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          Payment Terms
        </h2>
        {openSection === "payment-terms" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
              <strong>Fees:</strong> All fees for services are specified in the service agreement.<br />
              <strong>Payment:</strong> Payments are due as specified in the invoice. Late payments may incur additional charges.
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("privacy-policy")}
        >
          {openSection === "privacy-policy" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          Privacy Policy
        </h2>
        {openSection === "privacy-policy" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
              <strong>Data Collection:</strong> We collect personal information to provide and improve our services.<br />
              <strong>Data Use:</strong> Your personal data will be used in accordance with our Privacy Policy.<br />
              <strong>Data Protection:</strong> We implement appropriate security measures to protect your data.
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("limitation-of-liability")}
        >
          {openSection === "limitation-of-liability" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          Limitation of Liability
        </h2>
        {openSection === "limitation-of-liability" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
              <strong>No Warranties:</strong> Our website and services are provided “as is” without any warranties.<br />
              <strong>Limitation:</strong> APTECHMEDIA will not be liable for any indirect, incidental, or consequential damages arising from the use of our website or services.
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("indemnification")}
        >
          {openSection === "indemnification" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          Indemnification
        </h2>
        {openSection === "indemnification" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
              You agree to indemnify and hold harmless APTECHMEDIA from any claims, losses, damages, liabilities, and expenses arising out of your use of our website or services.
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("termination")}
        >
          {openSection === "termination" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          Termination
        </h2>
        {openSection === "termination" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
              <strong>By User:</strong> You may terminate your account at any time by contacting us.<br />
              <strong>By APTECHMEDIA:</strong> We reserve the right to terminate or suspend your access to our website and services for any reason without prior notice.
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("governing-law")}
        >
          {openSection === "governing-law" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          Governing Law
        </h2>
        {openSection === "governing-law" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
              These Terms and Conditions are governed by and construed in accordance with the laws of the jurisdiction in which APTECHMEDIA operates.
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("changes-to-terms")}
        >
          {openSection === "changes-to-terms" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          Changes to Terms
        </h2>
        {openSection === "changes-to-terms" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
              We may update these Terms and Conditions from time to time. The updated terms will be posted on our website, and your continued use of our services constitutes acceptance of the changes.
            </p>
          </div>
        )}
      </div>

      <div>
        <h2
          className="text-xl font-semibold cursor-pointer uppercase bg-gray-200 p-3 mb-2 flex items-center"
          onClick={() => toggleSection("contact-us")}
        >
          {openSection === "contact-us" ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
          Contact Us
        </h2>
        {openSection === "contact-us" && (
          <div className="pl-4">
            <p className="mb-5 text-gray-700 leading-relaxed">
              If you have any questions or concerns about these Terms and Conditions, please contact us at:<br />
              Email: info@aptechmedia.com<br />
              Phone: +923 33 9371 781<br />
              Address: AptechMedia PTCL Training Centre، 1st Floor, KP IT Park, University Rd, Canal Town, Peshawar, Khyber Pakhtunkhwa 25000
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Terms