import { FaFacebookF, FaTwitter, FaLinkedinIn, FaUserAlt, FaLaptopCode, FaBrain, FaProjectDiagram, FaPeopleArrows, FaLightbulb, FaAward, FaBuilding, FaRegSmile } from "react-icons/fa"
import { useState } from "react"
import team1 from "../assets/team/1.png"
import team2 from "../assets/team/2.png"
import team3 from "../assets/team/3.png"
import team4 from "../assets/team/4.png"
import team5 from "../assets/team/5.png"
import team6 from "../assets/team/6.png"
import team7 from "../assets/team/7.png"
import team8 from "../assets/team/8.png"
import team9 from "../assets/team/9.png"
import team10 from "../assets/team/10.png"
import team11 from "../assets/team/11.png"
import team12 from "../assets/team/12.png"
import team13 from "../assets/team/13.png"
import team14 from "../assets/team/14.png"

import teamMember1 from "../assets/team/team_hero/1.jpg"
import teamMember2 from "../assets/team/team_hero/2.jpg"
import teamMember3 from "../assets/team/team_hero/3.jpg"
import teamMember4 from "../assets/team/team_hero/4.jpg"

const teamImages = [team1, team2, team3, team4, team5, team6, team7, team8, team9, team10, team11, team12, team13, team14]

const Team = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 8

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const renderPagination = () => {
    const totalPages = Math.ceil(teamImages.length / itemsPerPage)
    const paginationItems = []

    for (let i = 1; i <= totalPages; i++) {
      paginationItems.push(
        <button
          key={i}
          onClick={() => handleClick(i)}
          className={`w-8 h-8 flex items-center justify-center rounded-full shadow ${
            currentPage === i ? "bg-blue-500 text-white" : "bg-gray-100 text-blue-500"
          }`}
        >
          {i}
        </button>
      )
    }

    return (
      <div className="flex justify-center space-x-2 mt-8">
        <button
          onClick={() => handleClick(currentPage - 1)}
          disabled={currentPage === 1}
          className="w-8 h-8 flex items-center justify-center rounded-full shadow bg-gray-100 text-blue-500"
        >
          &laquo;
        </button>
        {paginationItems}
        <button
          onClick={() => handleClick(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="w-8 h-8 flex items-center justify-center rounded-full shadow bg-gray-100 text-blue-500"
        >
          &raquo;
        </button>
      </div>
    )
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const selectedImages = teamImages.slice(startIndex, startIndex + itemsPerPage)
  return (
    <>
      <div className="relative bg-gradient-to-r from-blue-500 to-green-500">
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="container mx-auto p-4 pt-6 md:pt-12 flex flex-col md:flex-row items-center relative z-10">
          <div className="text-white md:w-1/2 p-4">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4">Meet Our Team</h1>
            <p className="text-lg md:text-xl lg:text-2xl mb-4">We are a diverse group of professionals dedicated to innovation and excellence in the mobile industry. Our team members bring unique skills and experiences to the table, making us stronger together.</p>
          </div>
          <div className="flex md:w-1/2 justify-center space-x-4 p-4 flex-wrap">
            <img src={teamMember1} alt="Team Member 1" className="w-32 h-32 rounded-full object-cover border-2 border-white" />
            <img src={teamMember2} alt="Team Member 2" className="w-24 h-24 rounded-full object-cover border-2 border-white" />
            <img src={teamMember3} alt="Team Member 3" className="w-32 h-32 rounded-full object-cover border-2 border-white" />
            <img src={teamMember4} alt="Team Member 4" className="w-24 h-24 rounded-full object-cover border-2 border-white" />
          </div>
        </div>
      </div>

      <div className="container mx-auto p-4 pt-6 md:pt-12 flex flex-col items-center">
        <h2 className="text-4xl font-bold text-black mb-4">Our Values</h2>
        <p className="text-gray-700 lg:text-2xl sm:text-base p-2 rounded mt-4 text-center">
          Innovation, collaboration, and excellence are at the core of everything we do. We strive to create a positive impact through our work.
        </p>
      </div>

      <div className="container mx-auto p-10">
        <h2 className="text-3xl font-bold text-black mb-8 text-center">Our Team Members</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {selectedImages.map((image, index) => (
            <div key={index} className="single-team active bg-white p-6 rounded shadow-lg min-h-[20rem]">
              <div className="relative w-40 h-40 mx-auto mb-4">
                <img
                  src={"https://jumpx-react.envytheme.com/images/team/team-shape.png"}
                  alt="Shape"
                  className="absolute inset-0 w-full h-full object-cover animate-spin-slow"
                />
                <div className="relative flex justify-center items-center w-full h-full">
                  <img
                    src={image}
                    alt={`Profile ${startIndex + index + 1}`}
                    className="w-28 h-28 rounded-full object-cover"
                  />
                </div>
              </div>
              <div className="team-content text-center mt-4">
                <h3 className="text-xl font-bold mb-1">Name</h3>
                <span className="text-gray-500 text-sm mb-2">Position</span>
                <ul className="flex justify-center space-x-4 mt-2">
                  <li>
                    <a href="https://facebook.com/" target="_blank" className="bg-gray-100 w-8 h-8 flex items-center justify-center rounded-full shadow" rel="noreferrer">
                      <FaFacebookF className="text-blue-500" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/" target="_blank" className="bg-gray-100 w-8 h-8 flex items-center justify-center rounded-full shadow" rel="noreferrer">
                      <FaTwitter className="text-blue-500" />
                    </a>
                  </li>
                  <li>
                    <a href="https://linkedin.com/" target="_blank" className="bg-gray-100 w-8 h-8 flex items-center justify-center rounded-full shadow" rel="noreferrer">
                      <FaLinkedinIn className="text-blue-500" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </div>
        {renderPagination()}
      </div>

      <div className="container mx-auto p-4 pt-6 md:pt-12 flex flex-col items-center">
        <h2 className="text-3xl font-bold text-black mb-8 text-center">Team Achievements</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <FaAward className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Award for Innovation</h3>
            <p className="text-center text-gray-700">Recognized for outstanding innovation in mobile app development.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <FaBuilding className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Best Workplace</h3>
            <p className="text-center text-gray-700">Voted as one of the best places to work in the industry.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <FaRegSmile className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Excellence in Customer Service</h3>
            <p className="text-center text-gray-700">Awarded for providing exceptional customer service and support.</p>
          </div>
        </div>
      </div>

      <div className="container mx-auto p-4 pt-6 md:pt-12 flex flex-col items-center">
        <h2 className="text-3xl font-bold text-black mb-8 text-center">Our Skills</h2>
        <p className="text-gray-700 lg:text-2xl sm:text-base p-2 rounded mt-4 text-center">
          Our team possesses a diverse set of skills to tackle any challenge and deliver outstanding results.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
          <div className="bg-blue-100 p-6 rounded-lg shadow-lg flex flex-col items-center">
            <FaUserAlt className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Leadership</h3>
            <p className="text-center text-gray-700">Guiding teams to success with strong leadership skills.</p>
          </div>
          <div className="bg-blue-100 p-6 rounded-lg shadow-lg flex flex-col items-center">
            <FaLaptopCode className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Technical Expertise</h3>
            <p className="text-center text-gray-700">Mastery of various technologies and programming languages.</p>
          </div>
          <div className="bg-blue-100 p-6 rounded-lg shadow-lg flex flex-col items-center">
            <FaBrain className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Innovation</h3>
            <p className="text-center text-gray-700">Constantly innovating to stay ahead in the industry.</p>
          </div>
          <div className="bg-blue-100 p-6 rounded-lg shadow-lg flex flex-col items-center">
            <FaProjectDiagram className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Project Management</h3>
            <p className="text-center text-gray-700">Ensuring projects are delivered on time and within budget.</p>
          </div>
          <div className="bg-blue-100 p-6 rounded-lg shadow-lg flex flex-col items-center">
            <FaPeopleArrows className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Collaboration</h3>
            <p className="text-center text-gray-700">Working together to achieve common goals efficiently.</p>
          </div>
          <div className="bg-blue-100 p-6 rounded-lg shadow-lg flex flex-col items-center">
            <FaLightbulb className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Creativity</h3>
            <p className="text-center text-gray-700">Bringing fresh and innovative ideas to the table.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Team