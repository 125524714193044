import { useState } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { FaArrowLeft } from "react-icons/fa"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { apiCall } from "../lib/api"

const JobForm = () => {
  const { jobId } = useParams()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { job } = state

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    gender: "",
    contact: "",
    qualification: "",
    cv: null
  })

  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }))
  }

  const handleFileChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      cv: e.target.files[0]
    }))
  }

  const validate = () => {
    let errors = {}

    if (!formData.name) errors.name = "Name is required"
    if (!formData.email) {
      errors.email = "Email is required"
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid"
    }
    if (!formData.gender) errors.gender = "Gender is required"
    if (!formData.contact) {
      errors.contact = "Contact number is required"
    } else if (!/^\d+$/.test(formData.contact)) {
      errors.contact = "Contact number must be numeric"
    }
    if (!formData.qualification) errors.qualification = "Qualification is required"
    if (!formData.cv) errors.cv = "CV is required"

    setErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (validate()) {
      const formDataToSend = new FormData()
      formDataToSend.append("name", formData.name)
      formDataToSend.append("email", formData.email)
      formDataToSend.append("gender", formData.gender)
      formDataToSend.append("contactNo", formData.contact)
      formDataToSend.append("qualification", formData.qualification)
      formDataToSend.append("jobId", jobId)
      formDataToSend.append("cv", formData.cv)

      try {
        const response = await apiCall("post", "/api/applyJob", formDataToSend, {
          "Content-Type": "multipart/form-data"
        })

        if (response.status === 1) {
          toast.success("Application submitted successfully. We will get back to you soon.", {
            autoClose: 3000
          })
        } else {
          toast.error("Failed to submit the application. Please try again.")
        }
      } catch (error) {
        toast.error("Failed to submit the application. Please try again.")
      }
    }
  }

  return (
    <div>
      <ToastContainer />
      <div
        className="relative w-full h-96 bg-cover bg-center"
        style={{
          backgroundImage:
            "url(https://imageio.forbes.com/specials-images/imageserve/458956405/0x0.jpg?format=jpg&height=900&width=1600&fit=bounds)"
        }}
      >
        <div className="absolute inset-0 bg-black opacity-70 h-full"></div>
        <div className="relative flex flex-col items-center justify-center h-full text-center text-white">
          <button
            onClick={() => navigate(-1)}
            className="absolute top-4 left-4 bg-blue-600 text-white rounded-full p-2 hover:bg-blue-700"
          >
            <FaArrowLeft className="w-6 h-6" />
          </button>
          <h1 className="text-3xl font-bold mb-2">{job.position}</h1>
          <p className="text-lg">Apply for the position at {job.department}</p>
        </div>
      </div>
      <div className="py-8 flex flex-col lg:flex-row items-center lg:items-start lg:justify-around min-h-screen">
        <div className="w-full lg:w-2/5 p-4">
          <h2 className="text-2xl font-bold mb-4 text-blue-600">Job Description</h2>
          <div
            dangerouslySetInnerHTML={{ __html: job.description }}
            className="text-gray-700 space-y-4 description"
          />
        </div>
        <form
          className="w-full max-w-lg bg-white p-8 rounded shadow-lg mt-8 lg:mt-0"
          onSubmit={handleSubmit}
        >
          <h2 className="text-2xl font-bold mb-6 text-center text-blue-600">Application Form</h2>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.name ? "border-red-500" : ""
              }`}
              required
            />
            {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.email ? "border-red-500" : ""
              }`}
              required
            />
            {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="gender">
              Gender
            </label>
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.gender ? "border-red-500" : ""
              }`}
              required
            >
              <option value="" disabled>
                Select your gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            {errors.gender && <p className="text-red-500 text-xs italic">{errors.gender}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contact">
              Contact Number
            </label>
            <input
              type="text"
              id="contact"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.contact ? "border-red-500" : ""
              }`}
              required
            />
            {errors.contact && <p className="text-red-500 text-xs italic">{errors.contact}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="qualification">
              Qualification
            </label>
            <input
              type="text"
              id="qualification"
              name="qualification"
              value={formData.qualification}
              onChange={handleChange}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.qualification ? "border-red-500" : ""
              }`}
              required
            />
            {errors.qualification && <p className="text-red-500 text-xs italic">{errors.qualification}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cv">
              Attach CV
            </label>
            <input
              type="file"
              id="cv"
              name="cv"
              onChange={handleFileChange}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.cv ? "border-red-500" : ""
              }`}
              required
            />
            {errors.cv && <p className="text-red-500 text-xs italic">{errors.cv}</p>}
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-blue-700"
            >
              Submit Application
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default JobForm