import AppContent from "../components/apps/AppContent"

import combatImage from "../assets/combat.png"
import warriorImage from "../assets/warrior.png"
import mathImage from "../assets/math.png"


const Apps = () => {
  return (
    <>
      <div className="container mx-auto p-4 pt-6 md:pt-12">
        <h1 className="text-5xl md:text-4xl lg:text-5xl xl:text-7xl font-bold text-black mb-8 sm:text-2xl xs:text-2xl text-center">Browse Our App Collection</h1>
        <p className="text-gray-700 lg:text-2xl sm:text-base p-2 rounded mt-4 text-center">
        Step into the vibrant universe of AptechMedia apps, where each game is a doorway to excitement <br/>and every update brings a new discovery. Immerse yourself in our world, where innovation <br/>meets fun, and every app is a new opportunity to play.
        </p>
      </div>
      <AppContent
        gradientColor="from-purple-600 to-blue-600"
        appimg={mathImage}
        appName="Maths - Equations Match"
        appHeading="Equation Explorer"
        appDesc="Maths - Equations Match is an engaging educational game designed to enhance mathematical skills by matching equations and solutions. Players are challenged to solve various math problems, improving their speed and accuracy in a fun, interactive way. Ideal for students and math enthusiasts, this game offers a great way to practice and sharpen mathematical abilities."
        videoSrc="https://www.datocms-assets.com/62418/1647340593-helix-jump.mp4"
      />
      <AppContent
        gradientColor="from-purple-600 to-blue-600"
        appimg={warriorImage}
        appName="Trailer: Valor Blade - Clash of Ages"
        appHeading="Sword Masters: Valor Blade"
        appDesc="Valor Blade - Clash of Ages is a captivating sword-fighting game that delves into the stories of 16 of the greatest swordsmen in human history. Players experience intense combat, utilizing unique skills and strategies as they battle legendary warriors from different eras. With immersive gameplay, rich historical settings, and a diverse roster of characters, the game offers a thrilling journey through the annals of swordsmanship."
        videoSrc="https://www.datocms-assets.com/62418/1647340593-helix-jump.mp4"
      />
      <AppContent
        gradientColor="from-purple-600 to-blue-600"
        appimg={combatImage}
        appName="Drone Strike Combat 2020"
        appHeading="Sky Guardians: Drone Strike"
        appDesc="Fly drones into assault firebase & become epic aerial dogfight guardian of skies. Command the epic call of drone battle jet fighter strike war."
        videoSrc="https://www.datocms-assets.com/62418/1647340593-helix-jump.mp4"
      />
      <div className="container mx-auto p-4 pt-6 md:pt-12 flex flex-col items-center">
        <h1 className="text-4xl md:text-3xl lg:text-4xl xl:text-6xl font-bold text-black mb-8 sm:text-xl xs:text-xl text-center">Become Part of Our Team</h1>
        <p className="text-gray-700 lg:text-2xl sm:text-base p-2 rounded mt-4 text-center">
        Join a team where innovation is the game and passion is the rule. At AptechMedia, We&apos;re crafting<br/> the next era of mobile gaming. Be part of our journey to the top—where your skills shape the future of play.
        </p>
        <a role="button" className="bg-blue-600 hover:bg-blue-700 text-white text-lg px-4 py-2 border text-center rounded-full mt-4">
          See our open Positions
        </a>
      </div>
    </>
  )
}

export default Apps
